import { Form, InputNumber, Space } from "antd";
import React, { useState, useRef, useEffect } from "react";
import { Button, Checkbox, Input, Switch, Tooltip } from "antd";
import { BsEmojiSmile } from "react-icons/bs";
import TokenFormStyle from "../../../Styles/TokenFormStyle";
import { RxCross2 } from "react-icons/rx";
import EmojiPicker from "emoji-picker-react";
import { PiWaves } from "react-icons/pi";
import { FiPlus } from "react-icons/fi";
import { FiMinus } from "react-icons/fi";
import ButtonStyle from "../../../Styles/ButtonStyle";
import useSigner from "../../../hooks/useSinger";
import { ethers } from "ethers";
import standardErc20 from "../../../contracts/StandaredERC20";
import {
  TooltipTexts,
  checkboxOptions,
  handleIncrementDecrement,
  tokenList,
} from "../../../utils/contast/contast";
import { toast, useToast } from "react-toastify";
import { useWeb3ModalAccount } from "@web3modal/ethers5/react";
import { CreateContractApi } from "../../../utils/api/api";
import BinanceDeployeModal from "../../Modal/BinanceDeployeModal";
import Loading from "../../Loading/Loading";

function BinanceForm(props) {
  const [showCreateOwner, setShowCreateOwner] = useState(false);
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const [selectedEmoji, setSelectedEmoji] = useState("");
  const [advancedSetting, setAdvancedSetting] = useState(false);
  const [selectedCheckboxes, setSelectedCheckboxes] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  //*************************** Emoji Picker ******************* */

  // const toggleEmojiPicker = () => {
  //   setShowEmojiPicker(!showEmojiPicker);
  // };

  // const onEmojiClick = (event, emojiObject) => {
  //   setSelectedEmoji(emojiObject.emoji);
  //   toggleEmojiPicker();
  // };

  // const emojiPickerRef = useRef();

  // const handleClickOutside = (event) => {
  //   if (
  //     emojiPickerRef.current &&
  //     !emojiPickerRef.current.contains(event.target)
  //   ) {
  //     setShowEmojiPicker(false);
  //   }
  // };
  // useEffect(() => {
  //   document.addEventListener("click", handleClickOutside);
  //   return () => {
  //     document.removeEventListener("click", handleClickOutside);
  //   };
  // }, []);

  // const suffix = (
  //   <div className="relative" ref={emojiPickerRef}>
  //     <BsEmojiSmile
  //       onClick={toggleEmojiPicker}
  //       style={{
  //         fontSize: 22,
  //         color: "#696969",
  //         cursor: "pointer",
  //       }}
  //     />
  //     <div
  //       className="absolute top-10 right-0"
  //       style={{ display: showEmojiPicker ? "block" : "none" }}
  //     >
  //       <EmojiPicker onEmojiClick={onEmojiClick} />
  //     </div>
  //   </div>
  // );

  //*************************************************8 */

  const onChangeOwner = (checked) => {
    setShowCreateOwner(checked);
    setAdvancedSetting(checked);
  };

  //**************** Increment or Decrement Button******************* */
  const [decimalValue, setDecimalValue] = useState(null);

  const { handleIncrement, handleDecrement } = handleIncrementDecrement(decimalValue,0,18);

  const PlusSuffix = (
    <button
      className="bg-[#E8E8E8] h-full flex items-center justify-center px-2 cursor-pointer"
      onClick={(e) => {
        e.preventDefault();
        setDecimalValue(handleIncrement());
      }}
    >
      <FiPlus size={22} style={{ color: "#696969" }} />
    </button>
  );

  const MinusSuffix = (
    <button
      className="bg-[#E8E8E8] h-full flex items-center justify-center px-2 cursor-pointer"
      onClick={(e) => {
        e.preventDefault();
        setDecimalValue(handleDecrement());
      }}
    >
      <FiMinus size={22} style={{ color: "#696969" }} />
    </button>
  );

  const handleCheckboxChange = (value) => {
    setSelectedCheckboxes((prevSelected) => {
      if (prevSelected.includes(value)) {
        return prevSelected.filter((checkbox) => checkbox !== value);
      } else {
        return [...prevSelected, value];
      }
    });
  };

  const { signer, address, provider } = useSigner();
  const { chainId, isConnected } = useWeb3ModalAccount();

  const handleContract = async (value) => {
    try {
      setLoading(true);
      const {
        ownerAddress,
        address_service,
        symbol,
        tokenName,
        otherConfig,
        decimal,
        initialSupply,
        advanceSetting,
      } = value;

      const signer = await provider.getSigner();
      const factory = new ethers.ContractFactory(
        standardErc20.abi,
        standardErc20.bytecode,
        signer
      );
      const contract = await factory.deploy(
        ownerAddress,
        tokenName,
        symbol,
        decimal,
        initialSupply,
        address_service,
        otherConfig.canMint,
        otherConfig.canPause,
        otherConfig.canBurn,
        otherConfig.canBlacklist
      );
      await contract.deployed();

      sessionStorage.setItem(
        "DeployeContract",
        JSON.stringify(contract?.address)
      );
      setLoading(false);
      return contract?.address;
    } catch (error) {
      console.error("Error deploying contract:", error);
      setLoading(false);
    }
  };

  //*********************** Api Integreation ********************************/

  const handleApiCall = async (values, contractResult) => {
    try {
      const jwtToken = JSON.parse(sessionStorage.getItem("loginResultJwtToken"));
      const userId = JSON.parse(sessionStorage.getItem("loginResultId"));
      const result = await CreateContractApi(values, contractResult, jwtToken, userId);
      return result;
    } catch (error) {
      console.error("Error calling API:", error);
      throw new Error("An error occurred while calling the API.");
    }
  };
  

  //*******************************************************/
  const handleSubmit = async (values) => {
    if (!isConnected) {
      toast.error("Please connect your wallet first.");
      return;
    }
    const otherConfigObject = {
      canBurn: selectedCheckboxes.includes("canBurn"),
      canMint: selectedCheckboxes.includes("canMint"),
      canPause: selectedCheckboxes.includes("canPause"),
      canBlacklist: selectedCheckboxes.includes("canBlacklist"),
    };

    const type = tokenList.filter((token) => token.chainIdNum === chainId)[0].type;
    values.otherConfig = otherConfigObject;
    values.advanceSetting = advancedSetting;
    values.address_service = "0xdCbc991ed111442617FA422764dc2D1997495AEB";
    values.type = type;
    otherConfigObject.canBlacklist = false;

    if (!advancedSetting) {
      values.ownerAddress = address;
    }

    console.log("Final--", values);

    try {
      const contractResult = await handleContract(values);
      if (contractResult) {
        const apiResult = await handleApiCall(values, contractResult);
      if (apiResult) {
        toast.success("Contract deployed and API call successful");
        setIsModalOpen(true);
      } else {
        toast.error("API call failed");
      }
      } else {
        console.error("Contract deployment failed or returned undefined result.");
        toast.error("Contract deployment failed or returned undefined result");
      }
    } catch (error) {
      console.error("Error handling contract submission:", error);
      toast.error("An error occurred while processing your request.");
    }finally {
      setLoading(false);
    }
  };

  //******************************************** */

  return (
    <>
      <Form onFinish={handleSubmit}>
        <div className="flex flex-col gap-[1.2rem">
          {/* Token Name  */}
          <div className=" flex justify-between w-full max-sm:flex-col max-sm:items-baseline">
            <div className="relative ">
              <div className="absolute text-xl text-red-500 top-1">*</div>
              <label
                htmlFor=""
                className="font-metropolis font-semibold text-[14px] leading-6 ml-2 top-3 absolute"
              >
                {" "}
                TokenName
              </label>
            </div>
            <Form.Item
              name="tokenName"
              rules={[
                {
                  required: true,
                  message: "Please input your TokenName!",
                },
                {
                  validator: (_, value) => {
                    if (!value) {
                      return Promise.reject(new Error("No spaces allowed"));
                    }
                    const trimmedValue = value.trim();
                    if (trimmedValue !== value) {
                      return Promise.reject(new Error("Blanck Space allowed"));
                    }

                    return Promise.resolve();
                  },
                },
              ]}
            >
              <div className="w-[600px] max-sm:w-[300px]">
                <Input
                  style={{
                    height: "47px",
                    borderRadius: "8px",
                    border: "1px solid #E8E8E8",
                    // paddingLeft: "0px",
                  }}
                  placeholder="Enter Token Name"
                  // suffix={suffix}
                />
              </div>
            </Form.Item>
          </div>

          {/* Symbol Name */}
          <div className=" flex justify-between w-full max-sm:flex-col max-sm:items-baseline">
            <div className="relative ">
              <div className="absolute text-xl text-red-500 top-1">*</div>
              <label
                htmlFor=""
                className="font-metropolis font-semibold text-[14px] leading-6 ml-2 top-3 absolute"
              >
                {" "}
                SymbolName
              </label>
            </div>

            <Form.Item
              name="symbol"
              rules={[
                {
                  required: true,
                  message: "Please input your SymbolName!",
                },
                {
                  validator: (_, value) => {
                    if (!value) {
                      return Promise.reject(new Error("No spaces allowed"));
                    }
                    const trimmedValue = value.trim();
                    if (trimmedValue !== value) {
                      return Promise.reject(new Error("Blanck Space allowed"));
                    }

                    return Promise.resolve();
                  },
                },
              ]}
            >
              <div className="w-[600px] max-sm:w-[300px]">
                <Input
                  style={{
                    height: "47px",
                    borderRadius: "8px",
                    border: "1px solid #E8E8E8",
                  }}
                  placeholder="Enter Symbol"
                  // suffix={suffix}
                />
              </div>
            </Form.Item>
          </div>

          {/*  initial supply */}
          <div className="flex justify-between w-full max-sm:flex-col max-sm:items-baseline">
            <div className="relative ">
              <div className="absolute text-xl text-red-500 top-1">*</div>
              <label
                htmlFor=""
                className="font-metropolis font-semibold text-[14px] leading-6 ml-2 top-3 absolute"
              >
                {" "}
                Initialsupply
              </label>
            </div>

            <Form.Item
              name="initialSupply"
              rules={[
                {
                  required: true,
                  message: "Please input your Initialsupply!",
                },
                {
                  pattern: /^[0-9]+$/,
                  message: "Please enter a valid number!",
                },
                {
                  validator: (_, value) => {
                    if (!value) {
                      return Promise.reject(new Error("No spaces allowed"));
                    }
                    const trimmedValue = value.trim();
                    if (trimmedValue !== value) {
                      return Promise.reject(new Error("Blanck Space allowed"));
                    }

                    return Promise.resolve();
                  },
                },
              ]}
            >
              <div className="w-[600px] max-sm:w-[300px]">
                <div className=" flex justify-between gap-4">
                  <div className="w-[380px]">
                    <Input
                      style={{
                        height: "47px",
                        borderRadius: "8px",
                        border: "1px solid #E8E8E8",
                      }}
                      placeholder="0"
                    />
                  </div>

                  <div className="w-[200px]">
                    <Input
                      style={{
                        height: "47px",
                        borderRadius: "8px",
                        border: "1px solid #E8E8E8",
                        cursor: "default",
                      }}
                      prefix={<PiWaves size={25} />}
                      placeholder="21 Million"
                      className="bg-[#E8E8E8] cursor-default"
                      disabled={true}
                    />
                  </div>
                </div>
              </div>
            </Form.Item>
          </div>

          {/* Decimals  */}
          <div className="flex justify-between w-full max-sm:flex-col max-sm:items-baseline">
            <div className="relative ">
              <div className="absolute text-xl text-red-500 top-1">*</div>
              <label
                htmlFor=""
                className="font-metropolis font-semibold text-[14px] leading-6 ml-2 top-3 absolute "
                style={{ whiteSpace: "nowrap" }}
              >
                {" "}
                Decimals(1-18)
              </label>
            </div>
            <Form.Item
              name="decimal"
              rules={[
                {
                  validator: (_, value) => {
                    if (value === 0 || value === null) {
                      return Promise.resolve();
                    }

                    if (
                      !Number.isInteger(Number(value)) ||
                      value < 1 ||
                      value > 18
                    ) {
                      return Promise.reject(
                        "Please enter a valid number between 1 and 18!"
                      );
                    }

                    return Promise.resolve();
                  },
                },
              ]}
              validateStatus={
                decimalValue === null ||
                (decimalValue >= 1 && decimalValue <= 18)
                  ? "success"
                  : "error"
              }
              help={
                decimalValue === null ||
                (decimalValue >= 1 && decimalValue <= 18)
                  ? ""
                  : "Please enter a valid number between 1 and 18!"
              }
            >
              <div className="w-[600px] max-sm:w-[300px]">
                <Input
                  style={{
                    height: "47px",
                    borderRadius: "8px",
                    border: "1px solid #E8E8E8",
                    padding: "0px",
                    textAlign: "center",
                  }}
                  suffix={PlusSuffix}
                  prefix={MinusSuffix}
                  placeholder="0"
                  className="flex justify-center "
                  onChange={(e) => setDecimalValue(Number(e.target.value))}
                  value={decimalValue}
                />
              </div>
            </Form.Item>
          </div>

          {/* advanced Settings */}
          <Form.Item name="advanceSetting" initialValue={advancedSetting}>
            <div className="flex gap-5">
              <span className="font-metropolis font-semibold text-2xl leading-5">
                Advanced Setting
              </span>
              <div>
                <Switch onChange={onChangeOwner} className="bg-[#F0F1F1]" />
              </div>
            </div>
          </Form.Item>

          {/*  */}

          {/* {showCreateOwner && ( */}
          <div
            className={`flex justify-between w-full max-sm:flex-col max-sm:items-baseline ${
              advancedSetting ? "" : "hidden"
            }`}
          >
            <div className="relative ">
              <label
                htmlFor=""
                className="font-metropolis font-semibold text-[14px] leading-6 ml-2 top-3 absolute"
              >
                {" "}
                Create/Owner
              </label>
            </div>

            <Form.Item
              name="ownerAddress"
              rules={[
                {
                  required: false,
                  message: "Please input owner address!",
                },
              ]}
            >
              <div className="w-[600px] max-sm:w-[300px]">
                <Input
                  style={{
                    height: "47px",
                    borderRadius: "8px",
                    border: "1px solid #E8E8E8",
                  }}
                  placeholder="0011X1........."
                />
              </div>
            </Form.Item>
          </div>
          {/* )} */}

          {/* Other Config  */}
          <div className="flex justify-between w-full max-sm:flex-col max-sm:items-baseline">
            <div className="relative ">
              <label
                htmlFor=""
                className="font-metropolis font-semibold text-[14px] leading-6 ml-2 absolute top-1"
                style={{ whiteSpace: "nowrap" }}
              >
                {" "}
                Other Config
              </label>
            </div>
            <Form.Item
              name="otherConfig"
              rules={[
                {
                  validator: (_, value) => {
                    if (!value || value.length === 0) {
                      return Promise.reject(
                        "Please select at least one option!"
                      );
                    } else {
                      return Promise.resolve();
                    }
                  },
                },
              ]}
            >
              <div className="w-[600px] max-sm:w-[300px] flex flex-wrap gap-[22px]">
                {checkboxOptions.map((option) => (
                  <Checkbox
                    value={option.value}
                    key={option.value}
                    onChange={() => handleCheckboxChange(option.value)}
                    checked={selectedCheckboxes.includes(option.value)}
                  >
                    <Tooltip
                      title={option.tooltip}
                      overlayStyle={{ maxWidth: "700px" }}
                    >
                      <span className="border-b-2 border-dashed">
                        {option.label}
                      </span>
                    </Tooltip>
                  </Checkbox>
                ))}
              </div>
            </Form.Item>
          </div>

          {/* Message one */}
          {/* <Form.Item name="message"> */}
          <div>
            <div className=" flex items-center justify-end w-full max-sm:flex-col max-sm:items-baseline">
              <div className="bg-[#807e7e] bg-opacity-10 p-4 w-[600px] max-sm:w-[300px] rounded-xl">
                <div className="flex items-center justify-between">
                  <div className="w-[90%]">
                    <p className="font-metropolis font-normal text-sm leading-6">
                      All tokens have been reviewed by the security audit
                      company and passed the contract security audit!
                    </p>
                  </div>
                  <span className="cursor-pointer">
                    <RxCross2 size={22} style={{ color: "#807e7e" }} />
                  </span>
                </div>
              </div>
            </div>
          </div>
          {/* </Form.Item> */}

          {/* Message Second */}
          {/* <Form.Item name="messageSecond"> */}
          <div className="mt-8">
            <div className=" flex items-center justify-end w-full max-sm:flex-col max-sm:items-baseline">
              <div className="bg-[#0074B7] bg-opacity-10 p-4 w-[600px] max-sm:w-[300px] rounded-xl">
                <div className="flex items-center ">
                  <p className="font-metropolis font-normal text-sm leading-6">
                    It only takes 10 seconds to create successfully, without
                    manual intervention, the token will be automatically
                    transferred to the address of the creator/owner after the
                    creation is successful. The token does not have any
                    copyright, it is automatically deployed to the main network
                    and verified!
                  </p>
                </div>
              </div>
            </div>
          </div>
          {/* </Form.Item> */}

          {/* */}
          <div className="mt-8 flex justify-center">
            <ButtonStyle>
              <Button
                type="submit"
                className={`bg-gradient-to-b from-[#006FD9] via-[#003B73] to-[#003B73] px-[1.875rem] h-[3rem] flex items-center gap-2 justify-center rounded-3xl hover:from-[#006FD9] hover:via-[#003B73] hover:to-[#003B73]`}
              >
                <span className="font-poppins font-medium text-base leading-[21px] text-white">
                  Create Token
                </span>
              </Button>
            </ButtonStyle>
          </div>

          <BinanceDeployeModal isOpen={isModalOpen}/>

          {/* <BinanceDeployeModal /> */}
        </div>
        {loading && <Loading/>}
      </Form>

      <TokenFormStyle />
    </>
  );
}

export default BinanceForm;
