// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.navbar {
    height: 80px;
    display: flex;
    justify-content: start;
    align-items: center;
    margin-left: 0px;
  }

  
  .nav-menu {
    background-color: #1b1b1b;
    width: 250px;
    height: 100vh;
    display: flex;
    justify-content: center;
    position: fixed;
    top: 0;
    left: -100%;
    transition: 850ms;
    z-index: 100;
  }
  
  .nav-menu.active {
    left: 0;
    transition: 350ms;
    z-index: 100;
  }
  
  .nav-text {
    display: flex;
    justify-content: start;
    align-items: center;
    padding: 8px 0px 8px 16px;
    list-style: none;
    height: 60px;
    color: #f5f5f5;
    gap: 15px;
  }
  
  .nav-text a {
    text-decoration: none;
    color: #f5f5f5;
    font-size: 18px;
    width: 95%;
    height: 100%;
    display: flex;
    align-items: center;
    padding: 0 16px;
    border-radius: 4px;
    gap: 30px;
  }
  
  .nav-text a:hover {
    background-color: #121212;
  }
  
  .nav-menu-items {
    width: 100%;
  }
  
  .navbar-toggle {
    background-color: #1b1b1b;
    width: 100%;
    height: 80px;
    display: flex;
    justify-content: start;
    align-items: center;
    padding-left: 30px;
   
  }
  
`, "",{"version":3,"sources":["webpack://./src/Components/Header/HamBurger/HamBurger.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,gBAAgB;EAClB;;;EAGA;IACE,yBAAyB;IACzB,YAAY;IACZ,aAAa;IACb,aAAa;IACb,uBAAuB;IACvB,eAAe;IACf,MAAM;IACN,WAAW;IACX,iBAAiB;IACjB,YAAY;EACd;;EAEA;IACE,OAAO;IACP,iBAAiB;IACjB,YAAY;EACd;;EAEA;IACE,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,yBAAyB;IACzB,gBAAgB;IAChB,YAAY;IACZ,cAAc;IACd,SAAS;EACX;;EAEA;IACE,qBAAqB;IACrB,cAAc;IACd,eAAe;IACf,UAAU;IACV,YAAY;IACZ,aAAa;IACb,mBAAmB;IACnB,eAAe;IACf,kBAAkB;IAClB,SAAS;EACX;;EAEA;IACE,yBAAyB;EAC3B;;EAEA;IACE,WAAW;EACb;;EAEA;IACE,yBAAyB;IACzB,WAAW;IACX,YAAY;IACZ,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,kBAAkB;;EAEpB","sourcesContent":[".navbar {\n    height: 80px;\n    display: flex;\n    justify-content: start;\n    align-items: center;\n    margin-left: 0px;\n  }\n\n  \n  .nav-menu {\n    background-color: #1b1b1b;\n    width: 250px;\n    height: 100vh;\n    display: flex;\n    justify-content: center;\n    position: fixed;\n    top: 0;\n    left: -100%;\n    transition: 850ms;\n    z-index: 100;\n  }\n  \n  .nav-menu.active {\n    left: 0;\n    transition: 350ms;\n    z-index: 100;\n  }\n  \n  .nav-text {\n    display: flex;\n    justify-content: start;\n    align-items: center;\n    padding: 8px 0px 8px 16px;\n    list-style: none;\n    height: 60px;\n    color: #f5f5f5;\n    gap: 15px;\n  }\n  \n  .nav-text a {\n    text-decoration: none;\n    color: #f5f5f5;\n    font-size: 18px;\n    width: 95%;\n    height: 100%;\n    display: flex;\n    align-items: center;\n    padding: 0 16px;\n    border-radius: 4px;\n    gap: 30px;\n  }\n  \n  .nav-text a:hover {\n    background-color: #121212;\n  }\n  \n  .nav-menu-items {\n    width: 100%;\n  }\n  \n  .navbar-toggle {\n    background-color: #1b1b1b;\n    width: 100%;\n    height: 80px;\n    display: flex;\n    justify-content: start;\n    align-items: center;\n    padding-left: 30px;\n   \n  }\n  \n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
