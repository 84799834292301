import React, { useState } from "react";
import { Button, Input, Modal } from "antd";
import ContrarctAddress from "../../img/ContractAddress.png";
import RightImg from "../../img/RightImg.png";
import SupportImg from "../../img/SupportImg.png";
import UnitImg from "../../img/UnitImg.png";

const BinanceDeployeModal = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = ({isOpen}) => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const DeployeAddress = JSON.parse(sessionStorage.getItem("DeployeContract"));
  return (
    <>
      <Button type="primary" onClick={showModal}>
        Open Modal
      </Button>
      <Modal
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        maskClosable={false}
        footer={null}
        width={600}
      >
        <div className=" w-full mt-10">
          <div className="flex justify-center items-center flex-col gap-2">
            <img src={RightImg} alt="" className="w-[3.5rem] h-[3.5rem]" />
            <span className="font-metropolis font-semibold text-2xl leading-24">
              Token Created Successfully
            </span>
          </div>

          <div className="gap-4 mt-12">
            <span className="text-lg">
              To get <b>Free</b>{" "}
            </span>

            <div className="flex h-10 gap-20  mt-4">
              <div className="flex  gap-2 items-center">
                <img src={SupportImg} alt="" className="h-full" />
                <span className="font-metropolis font-semibold text-xl">
                  Support
                </span>
              </div>

              <div className="flex  gap-2 items-center">
                <img src={UnitImg} alt="" className="h-full" />
                <span className="font-metropolis font-semibold text-xl">
                  Unit Test Cases
                </span>
              </div>
            </div>
          </div>

          <div className="flex  gap-6 mt-8 h-10 ">
            <Input placeholder="Enter Email Id" />
            <Input placeholder="Enter Contact No." />
          </div>

          <div className="flex  gap-6 mt-12 h-10  justify-center">
            <button className="w-full rounded-3xl border-2 border-[#0B6ECC] text-[#015586]"
            onClick={()=>{handleCancel()}}
            >Close</button>
            <button className="bg-green-300 w-full rounded-3xl bg-gradient-to-b from-[#0074B7]  to-[#003351] text-white">Submit</button>
          </div>
        </div>
      </Modal>
    </>
  );
};
export default BinanceDeployeModal;
