import { createGlobalStyle } from "styled-components";  


const SearchBarStyle = createGlobalStyle`
.ant-input-affix-wrapper >input.ant-input {
    padding-left: 10px;

}
`;
export default SearchBarStyle;
